import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/icon.svg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/ui/auth/magic-link-form.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/ui/auth/sign-in.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Separator"] */ "/vercel/path0/ui/ui/separator.tsx");
